@import "/src/styles/Variables/Variables.scss";

.upload_btn{
    display: inline-block;
    border: 1px solid #ced4da;
    color: #60677e;
    padding: 5px 10px;
    font-family:$sffont-regular;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
  }

.uploaded_imgsection{
    width: 100px;
    height: 150px;
}

#uploaded_img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.imgcancel_btn{
    border: none;
    font-family:$sffont-regular;
    font-size: 14px;
    padding: 5px 30px;
    background-color: #e2e3e7;
    margin-top: 5px;
    border-radius: 5px;

     &:hover{
        background-color: #a9a9a9;
     }
}

.close_icon_main{
    position: relative;
    margin-right: 1rem;
}
.close_icon{
    position: absolute;
    right: 0px;
    border-radius: 0px 5px 0px 0px;
    width: 20px;
    height: 20px;
    background-color: #ffffff;
    border: 1px solid #ced4da; 
}