@import "../../styles/Variables/Variables.scss";

/* Popup style */
.popupbox {
    position: fixed;
    background: rgb(0 0 0 / 46%);
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 99 ;
  }
   
  #box {
    position: relative;
    width: 55%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 70vh - 20px);
    border-radius: 4px;
    padding: 20px;
    overflow: auto;
  }
   
  .closeicon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(22% - 18px);
    top: calc(100vh - 73vh - 25px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
  }
  

  #addheading{
    font-size: 20px;
    font-family: $sffont-medium;
}

.nametitle{
    font-size: 16px;
    font-family: $sffont-regular;
}
.name{
    font-size: 16px;
    color: $white-color;
    font-family: $sffont-regular;
}
.detailsdiv{
    padding: 5px;
    background-color: $white-color;
}

.profilepicdiv{
    // padding: 10px;
    border-radius: 5px;
}

.profile_imgsection{
    display: flex;
    justify-content: center;
    align-items: center;
}

.profilepic{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid #21252975;
    padding: 5px;
}

.namesection{
    text-align: center;
}

#profilename{
    font-size: 14px;
    font-family: $sffont-medium;
    margin-top: 30px;
    text-align: center;
}
.profileemail{
    font-size: 12px;
    font-family: $sffont-regular;
}
#profiledob{
    font-size: 12px;
    font-family: $sffont-regular;
}

#empdiv{
    padding:5px 0px 0px 15px;
    margin-bottom: 10px;
    border-radius: 5px;

    .title{
        font-family: $sffont-regular;
        font-size: 13px;
    }

    .empname{
        font-family: $sffont-medium;
        font-size: 14px;
        padding-bottom:8px;
        word-break: break-word;
    }
}



@media only screen and (max-width: 400px) {
    #box {
        width: 80%;
        margin: 90px 40px;
      }

      .closeicon {
        right: calc(32% - 64px);
        top: calc(23vh - 25px);
      }
  }